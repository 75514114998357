import React from "react";
import PageTitle from "../components/PageTitle";
import PageWrapper from "../components/PageWrapper";
import Seo from "../components/Seo";
import FAQ from "../sections/pricing/FAQ";
import PricingTable from "../sections/pricing/PricingTable";

const Pricing = (props) => {
  return (
    <>
      <PageWrapper headerDark footerDark>
        <Seo
          title="Opqo | Pricing | Mobile for Maximo"
          description="Simple, clear pricing including an option to pay when you use it, not when you don’t."
          pathname={props.location.pathname}
        />
        <PageTitle title="Pricing" titleDark>
        </PageTitle>
        <PricingTable />
        <FAQ />
      </PageWrapper>
    </>
  );
};
export default Pricing;
