import React from "react";
import styled from "styled-components";
import { Container } from "react-bootstrap";

import { Title, Section, Button } from "../../components/Core";
import { Link } from "gatsby";

const Table = styled.table`
  thead tr .sin-title-box {
    padding: 0 20px;
    height: 60px;
    color: #19191b;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: -0.56px;
  }
  .title-col {
    min-width: 300px;
    width: 100%;
  }
  .opqo-free-col,
  .payuse-col,
  .upfront-col {
    min-width: 195px;
  }

  .colored-row {
    background: #f7f7fb;
    border-top: 2px solid #fff;
    &:first-child {
      border-top: 0;
    }
    .sin-box.title-col {
      color: #19191b;
      font-weight: 700;
    }
    .sin-box {
      position: relative;
      padding: 0 20px;
      height: 65px;
      color: #696871;
      font-size: 18px;
      font-weight: 300;
      letter-spacing: -0.56px;
      .table-icon {
        font-size: 20px;
        &.na {
          color: #999999;
        }
        &.neg {
          color: #f04037;
        }
        &.pos {
          color: #67d216;
        }
      }
      &:before {
        content: "";
        background: #fff;
        height: 100%;
        top: 0;
        left: 0;
        position: absolute;
        width: 1px;
      }
    }
  }
`;

const ButtonSm = styled(Button)`
  width: 167px;
  min-width: auto;
  height: 50px;
  border-radius: 5px;
  border: 1px solid #eae9f2;
  background-color: #ffffff;
  color: ${({ theme }) => theme.colors.secondary};
  font-size: 18px;
  font-weight: 500;
  letter-spacing: -0.56px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  &:hover,
  &:focus {
    border: ${({ theme }) => `1px solid ${theme.colors.secondary}`} !important;
    background-color: ${({ theme }) => `${theme.colors.secondary}`} !important;
    color: #fff;
  }
`;

const PricingTable = () => {
  return (
    <>
      <Section>
        <Container>
          <div
            css={`
              overflow: auto;
              padding-bottom: 30px;
            `}
          >
            <Table>
              <thead>
                <tr>
                  <th scope="col" className="title-col sin-title-box"> </th>
                  <th scope="col" className="opqo-free-col sin-title-box">
                    Opqo Free
                  </th>
                  <th scope="col" className="payuse-col sin-title-box">
                    Pay-as-you-use
                  </th>
                  <th scope="col" className="upfront-col sin-title-box">
                    Up-front
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className="colored-row">
                  <th scope="row" className="title-col sin-box">
                    First 5 users
                  </th>
                  <td className="opqo-free-col sin-box">Free, forever</td>
                  <td className="payuse-col sin-box">Free, forever</td>
                  <td className="upfront-col sin-box">Free, forever</td>
                </tr>
                <tr className="colored-row">
                  <th scope="row" className="title-col sin-box">
                    All application features
                  </th>
                  <td className="opqo-free-col sin-box">
                    <span className="table-icon pos">
                      <i className="fas fa-check"></i>
                    </span>
                  </td>
                  <td className="payuse-col sin-box">
                    <span className="table-icon pos">
                      <i className="fas fa-check"></i>
                    </span>
                  </td>
                  <td className="upfront-col sin-box">
                    <span className="table-icon pos">
                      <i className="fas fa-check"></i>
                    </span>
                  </td>
                </tr>
                <tr className="colored-row">
                  <th scope="row" className="title-col sin-box">
                    Regular application updates
                  </th>
                  <td className="opqo-free-col sin-box">
                    <span className="table-icon pos">
                      <i className="fas fa-check"></i>
                    </span>
                  </td>
                  <td className="payuse-col sin-box">
                    <span className="table-icon pos">
                      <i className="fas fa-check"></i>
                    </span>
                  </td>
                  <td className="upfront-col sin-box">
                    <span className="table-icon pos">
                      <i className="fas fa-check"></i>
                    </span>
                  </td>
                </tr>
                <tr className="colored-row">
                  <th scope="row" className="title-col sin-box">
                    Up front payment
                  </th>
                  <td className="opqo-free-col sin-box">
                    <span className="table-icon na">
                      <i className="fas fa-minus"></i>
                    </span>
                  </td>
                  <td className="payuse-col sin-box">
                    <span className="table-icon neg">
                      <i className="fas fa-times"></i>
                    </span>
                  </td>
                  <td className="upfront-col sin-box">
                    <span className="table-icon pos">
                      <i className="fas fa-check"></i>
                    </span>
                  </td>
                </tr>
                <tr className="colored-row">
                  <th scope="row" className="title-col sin-box">
                    Monthly billing for active users
                  </th>
                  <td className="opqo-free-col sin-box">
                    <span className="table-icon na">
                      <i className="fas fa-minus"></i>
                    </span>
                  </td>
                  <td className="payuse-col sin-box">
                    <span className="table-icon pos">
                      <i className="fas fa-check"></i>
                    </span>
                  </td>
                  <td className="upfront-col sin-box">
                    <span className="table-icon neg">
                      <i className="fas fa-times"></i>
                    </span>
                  </td>
                </tr>
                <tr className="colored-row">
                  <th scope="row" className="title-col sin-box">
                    Automated usage reporting
                  </th>
                  <td className="upfront-col sin-box">
                    <span className="table-icon neg">
                      <i className="fas fa-times"></i>
                    </span>
                  </td>
                  <td className="payuse-col sin-box">
                    <span className="table-icon pos">
                      <i className="fas fa-check"></i>
                    </span>
                  </td>
                  <td className="upfront-col sin-box">
                    <span className="table-icon neg">
                      <i className="fas fa-times"></i>
                    </span>
                  </td>
                </tr>
                <tr className="colored-row">
                  <th scope="row" className="title-col sin-box">
                    Manage Maximo connection information
                  </th>
                  <td className="opqo-free-col sin-box">Requires registration</td>
                  <td className="upfront-col sin-box">
                    <span className="table-icon pos">
                      <i className="fas fa-check"></i>
                    </span>
                  </td>
                  <td className="upfront-col sin-box">
                    <span className="table-icon pos">
                      <i className="fas fa-check"></i>
                    </span>
                  </td>
                </tr>
                <tr className="colored-row">
                  <th scope="row" className="title-col sin-box">
                    Customer support
                  </th>
                  <td className="opqo-free-col sin-box">Via email</td>
                  <td className="payuse-col sin-box">
                    <span className="table-icon pos">
                      <i className="fas fa-check"></i>
                    </span>
                  </td>
                  <td className="upfront-col sin-box">
                    <span className="table-icon pos">
                      <i className="fas fa-check"></i>
                    </span>
                  </td>
                </tr>
                <tr className="price-block-row">
                  <th scope="row" className="title-col sin-box"> </th>
                  <td className="opqo-free-col sin-box">
                    <div className="mt-5 ml-3 pr-3">
                      <Title variant="card">Free</Title>
                      <Link to="/get-started">
                        <ButtonSm size="sm" className="mt-3">Get Started</ButtonSm>
                      </Link>
                    </div>
                  </td>
                  <td className="opqo-free-col sin-box">
                    <div className="mt-5 ml-3 pr-3">
                      <Title variant="card">$40/user/mo</Title>
                      <Link to="/get-started">
                        <ButtonSm size="sm" className="mt-3">Get Started</ButtonSm>
                      </Link>
                    </div>
                  </td>
                  <td className="opqo-free-col sin-box">
                    <div className="mt-5 ml-3 pr-3">
                      <Title variant="card">$440/user/yr</Title>
                      <Link to="/get-started">
                        <ButtonSm size="sm" className="mt-3">Get Started</ButtonSm>
                      </Link>
                    </div>
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        </Container>
      </Section>
    </>
  );
};

export default PricingTable;
